.App {
  background-color: #98a2b6;
  text-align: center;
  color: white;
  margin: auto;
  min-height: 100vh;
  font-size: calc(20px + 2vmin);
  overflow: hidden;
}

.ReloadButton {
  font-size: 32px;
  font-family: "teko", sans-serif;
  text-shadow: black 1px 1px;
  border-radius: 10px;
  color: white;
  background-color: darkgrey;
  transition: padding 0.1s ease-in-out;
}

.ReloadButton:hover {
  padding: 0.1em 0.5em 0.1em 0.5em;
  background-color: rgb(90, 99, 102);
}

.ReloadButton:active {
  background-color: grey;
}

.Deco {
  display: inline-block;
  font-size: 1.5em;
  text-shadow: black 2px 2px;
}

.flipped {
  transform: scale(-1, 1);
  text-shadow: black -2px 3px;
}

.Title {
  font-family: "teko", sans-serif;
  font-weight: 500;
  display: inline-block;
  padding: 0 .2em 0 .2em;
  text-align: center;
  background-color: rgb(90, 99, 102);
  border-radius: 10px;
  font-size: 58px;
  line-height: 1.25em;
  font-weight: bold;
  margin: auto;
  text-shadow: black 2px 2px;
  box-shadow: black 1.5px 1.5px;
  margin-top: .1em;
  @media screen and (max-width: 390px) {
    font-size: 40px;
  }
  @media screen and (max-width: 300px) {
    font-size: 30px;
  }
}

h5 {
  font-family: "teko", sans-serif;
  letter-spacing: .025em;
  margin: auto;
  display: inline-block;
  background-color: rgb(90, 99, 102);
  border-radius: 10px;
  padding: 0 .2em 0 .2em;
  box-shadow: black 1.5px 1.5px;
  text-shadow: black 1px 1px;
}

.Timer {
  font-family: "teko", sans-serif;
  font-weight: 500;
  margin: auto;
  display: inline-block;
  background-color: rgb(90, 99, 102);
  border-radius: 10px;
  padding: 0 .2em 0 .2em;
  box-shadow: black 1.5px 1.5px;
  text-shadow: black 1px 1px;
}

.Board {
  display: inline-block;
  background-color: rgb(90, 99, 102);
  padding: .25em .15em .1em .15em;
  border-radius: 10px;
  box-shadow: black 1.5px 1.5px;
}

.break {
  display: block;
  margin-bottom: 0.25em;
  @media screen and (max-width: 300px) {
    margin-bottom: 0.1rem;
  }
}

.Cell {
  margin-left: .1em;
  margin-right: .1em;
  background-color: lightgrey;
  display: inline-block;
  box-shadow: black 1.5px 1.5px;
  height: 1.5em;
  width: 1.5em;
  line-height: 1.5em;
  border-radius: 5px;
  cursor: pointer;
  vertical-align: top;
  color: #282c34;
  text-shadow: black 1px 1px;
  font-weight: bold;
  user-select: none;
  transform: background-color .5s ease-in-out;
  @media screen and (max-width: 390px) {
    height: 1.2em;
    width: 1.2em;
    line-height: 1.2em;
  }
  @media screen and (max-width: 300px) {
    height: 1em;
    width: 1em;
    line-height: 0.5em;
  }
}

.isClicked {
  background-color: darkgrey !important;
}

.Cell:hover {
  background-color: rgb(191, 191, 191);
  animation: rotate .1s ease-in-out;
  animation-fill-mode: both;
}

@keyframes rotate {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(5deg);}
}

.helpButton {
  display: inline-block;
  background-color: lightgrey;
  border-radius: 100px;
  font-size: 24px;
  padding: 0px 12px 0px 12px;
  transition: padding .1s ease-in-out;
  margin-top: -.1em;
  margin-left: .5em;
  margin-right: .5em;
  box-shadow: black 1.5px 1.5px;
  text-shadow: black 1px 1px;
}

.helpButton:hover {
  background-color: rgb(90, 99, 102);
  padding: 2px 13px 2px 13px;
}

.helpButton:active {
  background-color: grey;
  padding: 2px 13px 2px 13px;
}

@keyframes expand {
  from {transform: scaleX(1) scaleY(1); background-color: lightgrey;}
  to {transform: scaleX(1.1) scaleY(1.1); background-color: grey;}
}

.helpScreen {
  font-family: "teko", sans-serif;
  position: absolute;
  visibility: hidden;
  background-color: rgb(90, 99, 102);
  font-size: 1.75rem;
  text-align: center;
  overflow-y: scroll;
  color: white;
  left: 50%;
  top: 50%;
  box-shadow: black 1.5px 1.5px;
  max-width: 10em;
  padding: 0.25em;
  margin-right: -50%;
  transform: translate(-50%, -70%);
  max-height: 8em;
  border-radius: 5px;
  transition: opacity .5s ease-in;
  text-shadow: black 1px 1px;
}


.OverlayScreen {
  font-family: "teko", sans-serif;
  letter-spacing: 0.075em;
  font-weight: 500;
  font-size: 64px;
  position: absolute;
  visibility: hidden;
  box-shadow: black 1.5px 1.5px;
  background-color: rgb(90, 99, 102);
  opacity: 0%;
  left: 50%;
  top: 50%;
  margin-right: -50%;
  transform: translate(-50%, -60%);
  width: 5em;
  height: 3em;
  border-radius: 5px;
  transition: opacity .5s ease-in;
  text-shadow: black 1px 1px;
}

.visibility {
  visibility: visible;
  opacity: 92.5%;
}

.fading {
  transition: opacity .5s ease-in;
  opacity: 0%;
}